import { ref } from 'vue'
export default function SurveyActivate(http, constant, toast) {
    const activate_status = ref(false)
    async function activateSurvey(id) {
        try {
            activate_status.value = true
            const f = new URLSearchParams()
            f.append('id', id)
            const response = await http.post(constant.SURVEY_ACTIVATE, f)
            toast.add({severity:'success', summary: 'Success', detail:'Survey was successfully activated.', life: 3000});
            activate_status.value = false
        } catch (err) {
            activate_status.value = false
        }
    }
    return {
        activate_status,
        activateSurvey
    }
}