import { ref } from 'vue'
export default function TermsGetList(http, constant, store) {
    let term_loading = ref(false)
    let term_list = ref([])

    async function getTermList(class_id, year) {
        term_loading.value = true;
        term_list.value = []
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('year', year)
            
            const response = await http.post(constant.TERM_LIST, f)
            term_loading.value = false
            term_list.value = response.data.data
        } catch (err) {
            term_list.value = false;
        }
    }
    return {
        term_loading, term_list, getTermList
    }
}