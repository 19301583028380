import { ref } from 'vue'
export default function SurveyShareReport(http, constants, toast) {
    const share_status = ref(false)

    async function shareSurveyReport(class_id, term, week_no, email) {
        try {
            share_status.value = true

            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('term', term)
            f.append('week_no', week_no)
            f.append('email', email)

            const response = await http.post(constants.SURVEY_SHARE_REPORT, f)
            share_status.value = false
            toast.add({severity:'success', summary: 'Success', detail:'Survey result was successfully sent to the list of emails you provided.', life: 3000});

        } catch (err) {
            share_status.value = false
        }
    }
    return {
        share_status,
        shareSurveyReport
    }
}