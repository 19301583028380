<template>
<Dialog header="Activate a survey"
      :draggable="false"  :modal="true" v-model:visible="display" :maximizable="true"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
      <div>
            <div class="form-group">
                <label for="exampleSelect1">Select a survey <span class="text-danger">*</span></label>
                <Dropdown class="w-100" name="school" v-model="selected_survey" :options="survey_list" optionLabel="name" :filter="true" placeholder="Select a survey" :showClear="true">
                    <template #value="slotProps">
                        <div class="country-item country-item-value" v-if="slotProps.value">
                            <div>{{slotProps.value.name}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.name}}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>  
      </div>
        <div>
            <p>Current active survey will be replaced</p>
            <button :disabled="create_status" type="button" @click="save" class="btn btn-primary mr-2">
                <span v-if="!create_status" >Save </span>
                <span v-else> <i class="fas fa-spinner fa-spinner"></i> Saving...</span>
            </button>
            <button @click="display = false"  type="button" class="btn btn-secondary">Cancel</button>
        </div>
</Dialog>
</template>
<script>
import  SurveyActivate  from '@/composables/survey/SurveyActivate'


import { defineComponent, ref, inject, watch} from 'vue'
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
    props: {
        add_survey_active_watcher: {
            type: Number,
            default: 1
        },
        survey_list:  {
            default: []
        }
    },
    components:  { Dialog, Dropdown},
    setup(props,  { root }) {
        const { toast, http, constants } = inject('App')
        const { getSurveyList } = inject('SurveyParent');

        let create_status = ref(false)
        let display = ref(false)
        let selected_survey = ref('')

        const { activate_status, activateSurvey } = SurveyActivate(http, constants, toast)
        
        function save() {
            activateSurvey(selected_survey.value.id)
        }
        watch(activate_status, (n, o) => {
                getSurveyList()
                display.value = false
        });
        watch(()=>props.add_survey_active_watcher, (n) => {
            display.value = true
        })

        return {
            create_status, display, selected_survey,
            save
        }
    }
})
</script>
