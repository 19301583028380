import { ref } from 'vue'
export default function SurveyGetRespodent(http, constant) {
    let respondent_loading = ref(false)
    let survey_respodent_list = ref([])

    async function getSurveyRespondent(survey_id) {
        respondent_loading.value = true;
        survey_respodent_list.value = []
        try {

            const f = new URLSearchParams()
            f.append('survey_id', survey_id)
            const response = await http.post(constant.SURVEY_GET_RESPONDENT, f)
            respondent_loading.value = false
            survey_respodent_list.value = response.data.data.respondent
        } catch (err) {
            respondent_loading.value = false;
        }
    }
    return {
        respondent_loading,   survey_respodent_list, getSurveyRespondent
    }
}