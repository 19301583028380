import { ref } from 'vue'
export default function SurveyGetReportByTermAndWeekNo(http, constant) {
    let report_loading = ref(false)
    let survey_report = ref([])

    async function getSurveyReportByTermAndWeekNo(class_id, term, week_no, year) {
        report_loading.value = true
        survey_report.value = []
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('term', term)
            f.append('week_no', week_no)
            f.append('year', year)
            
            const response = await http.post(constant.SURVEY_GET_REPORT_BY_TERM_AND_WEEKNO, f)
            report_loading.value = false
            survey_report.value = response.data.data
        } catch (err) {
            report_loading.value = false
        }
    }
    return {
        report_loading, survey_report, getSurveyReportByTermAndWeekNo
    }
}