<template>
<div>
<Sidebar  v-model:visible="display" position="full"  :dismissable="false" :modal="true" :showCloseIcon="false" >
    <form  ref="surveyData" @submit.prevent="insertSurvey"  action="post"  >
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Survey</p>
                    <small v-if="type == 'add'">Create Survey</small>
                    <small v-else>Edit Survey</small>
                </div>
                <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content">
        <div class="container">
        <div class="p-10   pt-0 mt-0" style="    height: 5000px;" >
            <div class=" box-shadodwv2"> 
                <TabView v-model:activeIndex="activeTab">
                    <TabPanel header="Details">
                        <div class="form-group">
                                <label>Question Flow</label>
                                <div class="row">
                                    <div class="col-sm-12 col-md-2">
                                        <RadioButton id="multi" name="multi" :value="1" v-model="question_flow" />
                                        <label for="multi" class="checkbox-label">Multi Step</label>  
                                    </div>
                                    <div class="col-sm-12 col-md-2">
                                        <RadioButton id="long" name="long" :value="2" v-model="question_flow" />
                                        <label for="long" class="checkbox-label">Long Form</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group pt-3">
                                <label>Select Class <span class="text-danger">*</span></label>
                                <select class="form-control" name="class" v-model="selected_class">
                                    <option value="">Select</option>
                                    <option v-for="(item, i ) in class_list" :key="i" :value="item.id">{{item.name}}</option>
                                </select>
                                <span>Please dont select class if this survey does not belong to a class</span>
                            </div>

                            <div class="form-group pt-2">
                                <label>Survey Name</label>
                                <input type="text" class="form-control" required v-model="name" name="name"  placeholder="Enter survey name"/>
                            </div>
                            <div class="form-group">    
                                <label>Description</label>
                                <textarea class="form-control"  v-model="descriptions" name="descriptions" placeholder="Enter survey description"></textarea>
                            </div>
                            <div class="form-group">    
                                <label class="w-100">Thumbnail</label>
                                <el-radio-group v-model="thumbnail_type" style="margin-bottom: 30px">
                                    <el-radio-button label="color">Color</el-radio-button>
                                    <el-radio-button label="upload"  >Upload</el-radio-button>
                                    <el-radio-button label="image"  >Free Images</el-radio-button>
                                    <el-radio-button label="library"  >Image Library</el-radio-button>

                                </el-radio-group>
                                <div class="row pt-0 mt-0" v-if="thumbnail_type == 'color'">
                                    <div class="col-12 pt-0 mt-0">
                                        <div class="box-shadowv4 ">
                                            <el-color-picker v-model="thumbnail_color" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-0 mt-0" v-if="thumbnail_type == 'upload'">
                                    <div class="col-12 pt-0 mt-0">
                                        <div class="thumb_preview cursor-pointer" >
                                            <img v-if="upload_thumb != ''"  :src="`${web_url}${upload_thumb}`" style="width: 200px; height: 198px">
                                            <i  v-if="upload_thumb == ''"   class="pi pi-cloud-upload icon-download"></i>
                                            <i @click="resetUploadThumbPreview"  v-if="upload_thumb != ''" class="pi pi-times icon-close"></i>
                                        </div>
                                        <div class="upload-example__buttons-wrapper flex">
                                            <button  type="button" class="upload-example__button  mt-3" @click="$refs.fileThumb.click()">
                                                <input ref="fileThumb" type="file" accept="image/*" @change="loadImageThumb($event)" />Select Image
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-0 mt-0" v-if="thumbnail_type == 'library'">
                                    <div class="col-12 pt-0 mt-0">
                                        <div class="thumb_preview cursor-pointer">
                                            <img v-if="library_thumb != ''"  @click="showLibrary" :src="`${web_url}${library_thumb}`" style="width: 200px; height: 198px">
                                            <i  v-if="library_thumb == ''"  @click="showLibrary" class="pi pi-images icon-download"></i>
                                            <i @click="resetLibraryThumbPreview"  v-if="library_thumb != ''" class="pi pi-times icon-close"></i>
                                        </div>
                                        <span>Recommended size is 120x120</span>
                                    </div>
                                </div>
                                
                                <div class="row pt-0 mt-0" v-if="thumbnail_type == 'image'">
                                    <div class="col-12 pt-0 mt-0">
                                        <div class="thumb_preview cursor-pointer">
                                            <img v-if="survey_thumb != ''"  @click="uploadEvent" :src="`${web_url}${survey_thumb}`" style="width: 200px; height: 198px">
                                            <i  v-if="survey_thumb == ''"  @click="uploadEvent" class="pi pi-cloud-upload icon-download"></i>
                                            <i @click="resetPreview"  v-if="survey_thumb != ''" class="pi pi-times icon-close"></i>
                                        </div>
                                        <span>Recommended size is 120x120</span>
                                    </div>
                                </div>
                            </div>
                    </TabPanel>
                    <TabPanel header="Advanced Settings">
                        <Accordion :activeIndex="activeSettingsTab">
                            <AccordionTab header="Basic Settings">
                                <div class="form-group pt-2">
                                    <label>Survey Headline</label>
                                    <input type="text" class="form-control"  v-model="headline" name="headline"  placeholder="Enter headline"/>
                                </div>
                                <div class="form-group">    
                                    <label>Survey Sub-headline</label>
                                    <textarea class="form-control"   v-model="sub_headline" name="sub_headline" placeholder="Enter survey sub headline"></textarea>
                                </div>
                                <div class="form-group">
                                    <label class="w-100">Theme Color</label>
                                    <div class="box-shadowv4 ">
                                        <el-color-picker v-model="theme_color" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="w-100">Footer, Header, Button text color</label>
                                    <div class="box-shadowv4 ">
                                        <el-color-picker v-model="text_color" />
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Opt-in Settings">
                            <div class="form-group pt-2">
                                <el-switch
                                        v-model="is_optin"
                                        active-text="Opt-in?"
                                    >
                                    </el-switch>
                            </div>
                            <div class="form-group pt-2" v-if="is_optin">
                                <el-switch
                                :disabled="true"
                                        v-model="via_student_id"
                                        active-text="Submit via student ID"
                                    >
                                    </el-switch>
                                    <span class="text-danger">(Coming Soon)</span>
                            </div>

                            <div class="form-group" v-if="is_optin && !via_student_id">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 pt-2">
                                            <Checkbox id="fname" name="fname" value="fname" v-model="optin_fields" />
                                            <label for="fname" class="checkbox-label">First name</label>
                                        </div>
                                        <div class="col-sm-12 col-md-6  pt-2">
                                            <Checkbox id="lname" name="lname" value="lname" v-model="optin_fields" />
                                            <label for="lname" class="checkbox-label">Last name</label>
                                        </div>
                                        <div class="col-sm-12 col-md-6  pt-2">
                                            <Checkbox id="phone" name="phone" value="phone" v-model="optin_fields" />
                                            <label for="phone" class="checkbox-label">Phone</label>
                                        </div>
                                        <div class="col-sm-12 col-md-6  pt-2">
                                            <Checkbox id="address" name="address" value="address" v-model="optin_fields" />
                                            <label for="address" class="checkbox-label">Address</label>
                                        </div>
                                    </div>
                            </div>
                            
                                <div class="form-group pt-2" v-if="is_optin">
                                <el-switch
                                        v-model="send_copy"
                                        active-text="Send copy of results to respondent"
                                    >
                                    </el-switch>
                            </div>
                            </AccordionTab>
                            <AccordionTab header="Questions" class="question-tab">
                                <div>
                                    <PickList v-model="questions" listStyle="height:342px" :dataKey="id">
                                        <template #sourceHeader>
                                            Available
                                        </template>
                                        <template #targetHeader>
                                            Selected    
                                        </template>
                                        <template #item="slotProps">
                                                {{slotProps.item.question}}
                                        </template>
                                    </PickList>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Thank You Page">
                                <div class="form-group">    
                                    <label class="w-100">Thank you type</label>
                                    <el-radio-group v-model="thankyou_type" style="margin-bottom: 30px">
                                        <el-radio-button label="default">Default</el-radio-button>
                                        <el-radio-button label="redirect">Redirect</el-radio-button>
                                    </el-radio-group>
                                    <div class="row pt-0 mt-0" >
                                        <div class="col-12 pt-0 mt-0">
                                            <div class="form-group pt-2" v-show="thankyou_type == 'default'">
                                                <label>Thank you message</label>
                                                <textarea  class="form-control" v-model="thankyou_message" name="thankyou_message" />
                                            </div>
                                            <div class="form-group pt-2" v-show="thankyou_type == 'redirect'">
                                                <label>Redirect link</label>
                                                <input type="text" class="form-control" v-model="redirect_link"  name="redirect_link"  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </TabPanel>
                    <TabPanel header="SEO">
                        <Accordion :activeIndex="activeSeoTab">
                        <AccordionTab header="SEO">
                                <div>
                                    <div class="form-group pt-2">
                                        <label>SEO Keywords</label>
                                        <input type="text" v-model="seo_keywords" name="seo_keywords" class="form-control" />
                                        <span>Several keywords that describe your page best.</span>
                                    </div>
                                    <div class="form-group pt-2">
                                        <label>SEO Descriptions</label>
                                        <textarea  v-model="seo_desc" name="seo_desc" class="form-control" />
                                        <span>A longer description of your landing page.</span>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Social Share">
                                <div>
                                    <div class="form-group pt-2">
                                        <label>Title</label>
                                        <input type="text" v-model="social_share_title" name="social_share_title" class="form-control" />
                                        <span>The title of the page visible when someone is sharing your landing page.</span>
                                    </div>
                                    <div class="form-group pt-2">
                                        <label>Description</label>
                                        <textarea  v-model="social_share_desc" name="social_share_desc" class="form-control" />
                                        <span>A website description which appears when shared</span>
                                    </div>
                                    <div class="form-group pt-2">
                                        <label>Thumbnail</label>
                                        <div class="thumb_preview cursor-pointer"  style="height: 155px;min-height: 155px;">
                                            <img v-if="survey_social_thumb != ''"  :src="`${web_social_url}${survey_social_thumb}`" style="width: 300px; height: 155px">
                                            <i  v-if="survey_social_thumb == ''"   class="pi pi-cloud-upload icon-download" style="margin-top: 27px;"></i>
                                            <i @click="resetSocialThumbPreview"  v-if="survey_social_thumb != ''" class="pi pi-times icon-close"></i>
                                        </div>
                                        <div class="upload-example__buttons-wrapper flex">
                                            <button  type="button" class="upload-example__button  mt-3" @click="$refs.file.click()">
                                                <input ref="file" type="file" accept="image/*" @change="loadImage($event)" />
                                            Select Image
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </TabPanel>
                </TabView>
                <div v-if="type != 'question-select'">
                   
                </div>
            </div>
          </div>
        </div>
        </div>
          <div class="r-panel-footer drawer-footer--v2">
            <div class="r-panel-footer-content w-100" style="width: 100% !important">
                <button type="button" :disabled="create_status" @click="display = false" class="cursor-pointer btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                <button type="submit" :disabled="create_status"    class="btn btn-sm btn-info save mr-2">
                    <span  v-if="!create_status">  Save</span>
                    <span v-else><i class="fas fa-spinner fa-spinner"></i> Saving...</span>
                </button>
            </div>
        </div>
        </form>
     </Sidebar>
    
    <PexelsImages :modules="`SurveyModule/SET_SURVEY_THUMB`" :pexels_image_watcher="pexels_image_watcher"  :source='`survey_thumb`'></PexelsImages>
    <ImageLibrary :path="`thumbnail/survey/thumb`" :modules="`SurveyModule/SET_SURVEY_LIBRARY_THUMB`"   :thumbnail_type="`thumbnail`" :image_library_watcher="image_library_watcher" :type="`survey_library_thumb`"></ImageLibrary>
    <ImageCropper :modules="upload_module" :ratioH="selected_ratioh" :ratioW="selected_ratiow" :selected_image="selected_upload_image.src" :image_type="`blob`" :source="selected_upload_source" :image_cropper_watcher="image_cropper_watcher"></ImageCropper>
</div>
</template>
<script>

import  SurveyCreate  from '@/composables/survey/SurveyCreate'
import { defineComponent, ref, inject, watch, computed, onMounted, toRef, provide ,reactive} from 'vue'
import Sidebar from 'primevue/sidebar'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { ElSwitch, ElRadioGroup, ElRadioButton, ElColorPicker }   from  'element-plus'
import Checkbox from 'primevue/checkbox';
import 'vue-advanced-cropper/dist/style.css';
import PexelsImages from '@/components/PexelsImages.vue'
import PickList from 'primevue/picklist';
import ColorPicker from 'primevue/colorpicker';
import  QuestionsGetList  from '@/composables/questions/QuestionsGetList'
import _ from "lodash";
import RadioButton from 'primevue/radiobutton';
import ImageCropper from '@/components/ImageCropper.vue';
import ImageLibrary from '@/components/ImageLibrary.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default defineComponent({
    props: {
        add_survey_watcher: {
            type: Number,
            default: 1
        },
        selected_survey : {
            default: []
        },
        type: {
            default: 'add',
            type: String
        },
        class_list: {
            default: []
        }
    },
    components:  { TabView, TabPanel, ElColorPicker, ImageLibrary, Sidebar, Accordion, AccordionTab, ElSwitch, Checkbox, ElRadioGroup, ElRadioButton, PexelsImages, PickList, RadioButton, ImageCropper},
    setup(props,  { root }) {
        const { utils, constants, http, toast, store } = inject('App')
        const { getSurveyList } = inject('SurveyParent');
        
        
        const name = ref('')
        const descriptions = ref('')
        const display = ref(false)
        const surveyData = ref('')
        const selected_class = ref('')
        const is_optin = ref(true)
        const optin_fields = ref(['fname','lname','address','phone'])
        const question_flow = ref(2)
        const pexels_image_watcher = ref(1)
        const thumbnail_type = ref('color')
        const thankyou_type = ref('default')
        const thumbnail_color = ref('#893BF7')
        const theme_color = ref('#893BF7')
        const text_color = ref('#fffff')
        const questions = ref([])
        const selected_question_list = ref([])
        const social_thumbnail = reactive({
				src: null,
				type: null
        })
        const cropper = ref()
        const headline = ref('')
        const sub_headline = ref('')
        const social_share_title = ref('')
        const social_share_desc = ref('')
        const redirect_link = ref('')
        const thankyou_message = ref('')
        const image_cropper_watcher = ref(1)
        const send_copy = ref(true)
        const via_student_id = ref(false)
        const seo_keywords = ref('')
        const seo_desc = ref('')
        const activeTab = ref(0)
        const activeSettingsTab = ref(0)
        const activeSeoTab = ref(0)

        let selected_upload_image = reactive({
            src: null,
			type: null
        })
        let upload_thumb_set = reactive({
				src: null,
				type: null
        })
        const selected_ratioh = ref(1)
        const selected_ratiow = ref(1)
        const selected_upload_source = ref('survey_upload_thumb')
        const image_library_watcher = ref(1)
        const upload_module = ref('SurveyModule/SET_SURVEY_UPLOAD_THUMB')

        const { create_status, createSurvey } = SurveyCreate(http, constants, toast)
        const { loading, question_list, getQuestionsList } = QuestionsGetList(http, constants, store)

        let current_question_list =  computed(() => store.getters['QuestionsModule/GET_QUESTION_LIST'])
        let survey_thumb =  computed(() => store.getters['SurveyModule/GET_SURVEY_THUMB'])
        let survey_social_thumb =  computed(() => store.getters['SurveyModule/GET_SURVEY_SOCIAL_THUMB'])
        let upload_thumb =  computed(() => store.getters['SurveyModule/GET_SURVEY_UPLOAD_THUMB'])
        let library_thumb =  computed(() => store.getters['SurveyModule/GET_SURVEY_LIBRARY_THUMB'])


        function insertSurvey() {
            let image  = ''
            if (thumbnail_type.value == 'upload')
                image = upload_thumb.value
            else if (thumbnail_type.value == 'library')
                image = library_thumb.value
            else
                image = survey_thumb.value

            createSurvey(props.selected_survey.id,  props.type, surveyData.value, removeHex(thumbnail_color.value), thumbnail_type.value,
                        question_flow.value,  is_optin.value, optin_fields.value, thankyou_type.value,
                        questions.value[1], image, survey_social_thumb.value, via_student_id.value, send_copy.value,
                        removeHex(theme_color.value), removeHex(text_color.value))
        }
         function removeHex(str) {
            return str = str.substring(1);
        }
        function change({coordinates, canvas}) {
            console.log(coordinates, canvas)
        }
        function uploadEvent() {
          pexels_image_watcher.value++
        }
        function getFilteredList(all_list, selected_list) {
           return  _.differenceBy(all_list, selected_list, 'id')
        }
		function reset() {
			social_thumbnail.src = null
            social_thumbnail.type = null
		}
        function destroyed() {
            if (social_thumbnail.src) {
                URL.revokeObjectURL(social_thumbnail.src)
            }
        }
        function resetUploadThumbPreview() {
            if (props.type == 'add')
              store.commit('SurveyModule/SET_SURVEY_UPLOAD_THUMB', '')
            else 
              store.commit('SurveyModule/SET_SURVEY_UPLOAD_THUMB', props.selected_survey.thumbnail)
        }
        function resetLibraryThumbPreview() {
            if (props.type == 'add')
              store.commit('SurveyModule/SET_SURVEY_LIBRARY_THUMB', '')
            else 
              store.commit('SurveyModule/SET_SURVEY_LIBRARY_THUMB', props.selected_survey.thumbnail)
        }
        function loadImageThumb(event) {
			const { files } = event.target;
			if (files && files[0]) {
				if (upload_thumb_set.src) {
					URL.revokeObjectURL(upload_thumb_set.src)
				}
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
                    upload_thumb_set.src = blob
                    upload_thumb_set.type =  utils.getMimeType(e.target.result, files[0].type)
                    image_cropper_watcher.value++

                    selected_upload_image.src = upload_thumb_set.src
                    selected_upload_image.type = upload_thumb_set.type
                    selected_ratioh.value = 120
                    selected_ratiow.value = 120
                    selected_upload_source.value = 'survey_upload_thumb'
                    
                    upload_module.value = 'SurveyModule/SET_SURVEY_UPLOAD_THUMB'
                    console.log(1)
                    
				};
				reader.readAsArrayBuffer(files[0]);
			}
		}
        function loadImage(event) {
			const { files } = event.target;
			if (files && files[0]) {
				if (social_thumbnail.src) {
					URL.revokeObjectURL(social_thumbnail.src)
				}
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
                    social_thumbnail.src = blob
                    social_thumbnail.type =  utils.getMimeType(e.target.result, files[0].type)
                    image_cropper_watcher.value++

                    selected_upload_image.src = social_thumbnail.src
                    selected_upload_image.type = social_thumbnail.type

                    selected_ratioh.value = 630
                    selected_ratiow.value = 1200
                    selected_upload_source.value = 'survey_social_thumb'

                    upload_module.value = 'SurveyModule/SET_SURVEY_SOCIAL_THUMB'
                    console.log(2)

				};
				reader.readAsArrayBuffer(files[0]);
			}
		}
        function resetPreview() {
            if (props.type == 'add')
              store.commit('SurveyModule/SET_SURVEY_THUMB', '')
            else 
              store.commit('SurveyModule/SET_SURVEY_THUMB', props.selected_survey.thumbnail)
        }
        function resetSocialThumbPreview() {
            if (props.type == 'add')
              store.commit('SurveyModule/SET_SURVEY_SOCIAL_THUMB', '')
            else 
              store.commit('SurveyModule/SET_SURVEY_SOCIAL_THUMB', props.selected_survey.social_thumb)
        }
        function showLibrary() {
            image_library_watcher.value++
        }
        function closeDialog() {}
        watch(create_status, (n, o) => {
            if (create_status.value == false) {
                display.value = false
                if (create_status.value == false)
                    getSurveyList()
            }
        });

        watch(()=>props.add_survey_watcher, (n) => {
            display.value = true
            activeTab.value = 0
            activeSettingsTab.value = 0
            activeSeoTab.value = 0
            if (props.type == 'edit') {
                name.value = props.selected_survey.name
                descriptions.value = props.selected_survey.descriptions
                sub_headline.value = props.selected_survey.sub_headline
                headline.value = props.selected_survey.headline
                question_flow.value = props.selected_survey.question_flow
                thumbnail_type.value = props.selected_survey.thumbnail_type
                
                store.commit('SurveyModule/SET_SURVEY_THUMB', props.selected_survey)
                store.commit('SurveyModule/SET_SURVEY_SOCIAL_THUMB', props.selected_survey)
                store.commit('SurveyModule/SET_SURVEY_UPLOAD_THUMB', props.selected_survey)
                store.commit('SurveyModule/SET_SURVEY_LIBRARY_THUMB', props.selected_survey)

                social_share_desc.value = props.selected_survey.social_desc
                social_share_title.value = props.selected_survey.social_title

                if (props.selected_survey.optins != null)
                    optin_fields.value = props.selected_survey.optins.split(',')
                else  optin_fields.value = []

                thankyou_message.value = props.selected_survey.thankyou_message
                redirect_link.value = props.selected_survey.thankyou_link
                thumbnail_color.value = `#${props.selected_survey.thumbnail_bg}`
                thankyou_type.value = props.selected_survey.thankyou_type
                seo_keywords.value = props.selected_survey.seo_keywords
                seo_desc.value = props.selected_survey.seo_desc
                theme_color.value = `#${props.selected_survey.theme_color}`
                text_color.value = `#${props.selected_survey.text_color}`
                is_optin.value = (props.selected_survey.is_optin == 1) ? true : false
                send_copy.value = (props.selected_survey.send_copy == 1) ? true : false


            } else {
                name.value = 'My Survey'
                descriptions.value = 'Survey descriptions'
                sub_headline.value = 'Survey Sub headline'
                headline.value = 'My Survey'
                question_flow.value = 2
                thumbnail_type.value = 'color'
  
                store.commit('SurveyModule/SET_SURVEY_THUMB', '')
                store.commit('SurveyModule/SET_SURVEY_SOCIAL_THUMB','')
                store.commit('SurveyModule/SET_SURVEY_LIBRARY_THUMB', '')
                store.commit('SurveyModule/SET_SURVEY_UPLOAD_THUMB', '')


                social_share_desc.value = ''
                social_share_title.value = ''
                optin_fields.value = ['fname','lname','address','phone']
                thankyou_type.value = 'default'
                thankyou_message.value = ''
                redirect_link.value = ''
                thumbnail_color.value = '#893BF7'
                theme_color.value = '#893BF7'
                text_color.value = '#fffff'
                via_student_id.value = false
                send_copy.value = true
                seo_keywords.value = ''
                seo_desc.value = ''
                is_optin.value = true
            }
            selected_question_list.value = props.selected_survey.questionslist
            questions.value = [question_list.value, selected_question_list.value] 

            if (current_question_list.value.length == 0) {
                getQuestionsList()
            } else {
                if (props.type == 'edit')
                    questions.value = [getFilteredList(current_question_list.value,  selected_question_list.value),  selected_question_list.value] 
                else
                    questions.value = [getFilteredList(current_question_list.value,  selected_question_list.value),  []] 

            }
        })
        watch(current_question_list, (n, o) => {
            if (props.type == 'edit')
              questions.value = [getFilteredList(current_question_list.value,  selected_question_list.value),  selected_question_list.value] 
            else
              questions.value = [getFilteredList(current_question_list.value,  selected_question_list.value),  []] 

        });
        provide('CropperParent', { closeDialog })

        return {
            selected_class,
            name, descriptions, create_status, display, surveyData,
            insertSurvey, is_optin, optin_fields, question_flow,  change, uploadEvent,
            pexels_image_watcher, thumbnail_type, thumbnail_color, selected_question_list, current_question_list, questions,
            loadImage, reset, social_thumbnail, thankyou_type, survey_thumb, resetPreview,
            web_url: `${process.env.VUE_APP_AWS_URL}`, cropper,
            web_social_url: `${process.env.VUE_APP_AWS_URL}`,
            sub_headline, headline, social_share_desc , social_share_title, redirect_link, thankyou_message,
            image_cropper_watcher, survey_social_thumb, resetSocialThumbPreview, send_copy, via_student_id, seo_keywords, seo_desc,
            theme_color, text_color,
            showLibrary, loadImageThumb, image_library_watcher, upload_thumb, upload_thumb_set, library_thumb,
            resetUploadThumbPreview, resetLibraryThumbPreview,
            upload_module, selected_upload_image, selected_ratioh, selected_ratiow, selected_upload_source,
            activeTab, activeSettingsTab, activeSeoTab
        }
    }
})
</script>