import { ref } from 'vue'
export default function SurveyGetDefault(http, constant) {
    let sloading = ref(false)
    let default_survey = ref([])
    let student_list = ref([])
    let is_surveyed = ref(0)

    async function getDefaultSurvey(student_id, class_id, term, week_no) {
        sloading.value = true;
        default_survey.value = []
        student_list.value = []
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('student_id', student_id)
            f.append('term', term)
            f.append('week', week_no)

            const response = await http.post(constant.SURVEY_GET_DEFAULT, f)
            sloading.value = false
            default_survey.value = response.data.data.default_survey
            student_list.value = response.data.data.student_list
            is_surveyed.value = response.data.data.is_surveyed
        } catch (err) {
            sloading.value = false;
        }
    }
    return {
        sloading, default_survey, student_list, is_surveyed, getDefaultSurvey
    }
}