<template>
<Dialog header="Add Questions"
      :draggable="false"  :modal="true" v-model:visible="display" :maximizable="true"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '60vw'}">
      <div>
           <PickList v-model="questions" listStyle="height:342px" :dataKey="id">
            <template #sourceHeader>
                Available
            </template>
            <template #targetHeader>
                Selected    
            </template>
            <template #item="slotProps">
                    {{slotProps.item.question}}
            </template>
        </PickList>
      </div>
        <template #footer>
        <div class="pl-17 pt-4 mb-7">
                <button @click="display = false"  type="button" class="btn btn-secondary">Cancel</button>
                <button :disabled="create_status" type="button" @click="save" class="btn btn-primary mr-2 submit-button-modal-style">
                    <span v-if="!create_status" class="add-button-text-modal-style">Save </span>
                    <span v-else> <i class="fas fa-spinner fa-spinner"></i> Saving...</span>
                </button>
            </div>
        </template>
</Dialog>
</template>
<script>
import { defineComponent, ref, inject, watch, computed, onMounted} from 'vue'
import { useToast } from "primevue/usetoast";
import { useStore  } from 'vuex'
import Dialog from 'primevue/dialog';
import PickList from 'primevue/picklist';
import  SurveyUpdateQuestions  from '@/composables/survey/SurveyUpdateQuestions'
import  QuestionsGetList  from '@/composables/questions/QuestionsGetList'
import _ from "lodash";


export default defineComponent({
    props: {
        add_survey_question_watcher: {
            type: Number,
            default: 1
        },
        selected_survey : {
            default: []
        },
    },
    components:  { Dialog, PickList },
    setup(props,  { root }) {

        const { getSurveyList } = inject('SurveyParent');
        const { constants, http, toast, store } = inject('App')


        let questions = ref([])
        let selected_question_list = ref([])
        

        let loader = ref('')
        let display = ref(false)

        const { loading, question_list, getQuestionsList } = QuestionsGetList(http, constants, store)
        const { create_status, updateSurveyQuestions } = SurveyUpdateQuestions(http, constants, toast)


        let current_question_list =  computed(() => store.getters['QuestionsModule/GET_QUESTION_LIST'])

        function save() {
            updateSurveyQuestions(props.selected_survey.id, questions.value[1])  
        }
        function getFilteredList(all_list, selected_list) {

           return  _.differenceBy(all_list, selected_list, 'id')
        }

         watch(create_status, (n, o) => {
            if (create_status.value == false) {
                display.value = false
                getSurveyList()
            }
        });

        watch(()=>props.add_survey_question_watcher, (n) => {
            display.value = true
            selected_question_list.value = props.selected_survey.questionslist
            questions.value = [question_list.value, selected_question_list.value] 

            if (current_question_list.value.length == 0) {
                getQuestionsList()
            } else {
                questions.value = [getFilteredList(current_question_list.value,  selected_question_list.value),  selected_question_list.value] 
            }

        })


        watch(current_question_list, (n, o) => {
              questions.value = [getFilteredList(current_question_list.value,  selected_question_list.value),  selected_question_list.value] 
        });

        return {
            loader, display, create_status,
            selected_question_list, current_question_list, questions,
            save
        }
    }
})
</script>
