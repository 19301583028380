<template>
<DataTable  class="p-datatable-sm" ref="dt"  :value="survey_list"
        dataKey="id"   filterDisplay="menu" :filters="filter.filter_list"  :loading="loading" responsiveLayout="stack" breakpoint="960px"
        :paginator="true" :rows="6"
        :resizableColumns="true" columnResizeMode="fit"
         :rowsPerPageOptions="[6, 10,20,50, 100, 1000]"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
            </template>
            <Column field="name" header="Name" :sortable="true">
                <template #body="slotProps">
                        <span class="d-flex">
                            <Avatar v-if="slotProps.data.thumbnail_type == 'image'
                                        || slotProps.data.thumbnail_type == 'upload' 
                                        || slotProps.data.thumbnail_type == 'library'" :image="`${web_url}${slotProps.data.thumbnail}`" class="p-mr-2" size="large" shape="circle" />
                            <div  v-if="slotProps.data.thumbnail_type == 'color'" :style="{ 'background-color': `#${slotProps.data.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"></div>
                            <p class="ml-5 pt-4">{{slotProps.data.name}}</p>
                        </span>
                </template>
            </Column>
            <Column field="descriptions" header="Descriptions" :sortable="true"></Column>
            <Column field="slug" header="URL" :sortable="true">
                <template #body="slotProps">
                    <a href="Javascript:void(0)" @click="copyLink(`${ënv.VUE_APP_API_SITE}survey/${slotProps.data.id}/${slotProps.data.slug}`)" v-tooltip.top="'Copy Link'" >{{slotProps.data.slug}}</a>
                </template>
            </Column>
            <Column field="respondent" header="Respondent" :sortable="true">
                <template #body="slotProps">
                        <a href="Javascript:void(0)" @click="viewRespondent(slotProps.data)"  v-tooltip.top="'Respondent Count'" >
                        {{ slotProps.data.respondent }}
                    </a>
                </template>
            </Column>
            <Column field="created_at" header="Created At" :sortable="true">
                <template #body="props">
                     <p class="text-muted pt-2" >{{ moment(props.data.created_at).format('LL') }}</p>
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps" >
                        <a href="javascript:void(0)"  v-tooltip.top="'Copy Link'"  @click="copyLink(`${ënv.VUE_APP_API_SITE}survey/${slotProps.data.id}/${slotProps.data.slug}`)" class=" mr-2   action-edit actions-table ml-2">
                        <span class="svg-icon svg-icon-primary svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z" fill="#000000" opacity="0.3" transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) "/>
                                    <path d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z" fill="#000000" transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) "/>
                                </g>
                            </svg>
                        </span>
                    </a>
                    <a href="javascript:void(0)"  v-tooltip.top="'Preview survey'"  @click="previewSurvey(slotProps.data)" class=" mr-3   action-edit actions-table ml-2">
                        <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"/>
                            </g>
                            </svg>
                        </span>
                    </a>
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                                <span class="svg-icon  svg-icon-primary svg-icon-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1">
                                            <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                            <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                        </g>
                                    </svg>
                                </span>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu v-if="slotProps.data.deletable == 1">
                                <el-dropdown-item :command="{ action: 'edit', data: slotProps.data }" ><i class="pi pi-pencil text-primary"></i> Edit Survey </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'questions', data: slotProps.data }"><i class="pi pi-question text-primary"></i> Update Questions</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'respondents', data: slotProps.data }"><i class="pi pi-users text-primary"></i> Survey Respondents</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'delete', data: slotProps.data }"><i class="pi pi-trash text-primary"></i>  Delete Survey</el-dropdown-item>
                            </el-dropdown-menu>
                            <el-dropdown-menu v-else>
                                <el-dropdown-item :command="{ action: 'preview', data: item}"><i class="pi pi-search text-primary"></i>  Preview</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'default-report', data: item}"><i class="pi pi-chart-bar text-primary"></i>  Reports</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </Column>
            <template #loading>
                Loading customers data. Please wait.
            </template>
            <template #empty>
                No survey found.
            </template>
</DataTable>
</template>
<script>
import { defineComponent, inject, toRef } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'
import moment from 'moment'
import Avatar from 'primevue/avatar';

export default defineComponent({
    components: {  DataTable, Column, ElDropdownMenu, Avatar, ElDropdownItem, ElDropdown },
    props: {
        survey_list: {
            default: []
        },
        loading: {
            default: false,
            type: Boolean
        },
        filter: {
            default: []
        }
    },
    setup(props) {
        const { constants} = inject('App')
        const { copyLink, previewSurvey, handleCommand, viewRespondent } = inject('SurveyParent')
        return {
            web_url: `${process.env.VUE_APP_AWS_URL}`,
            ënv: process.env, copyLink, previewSurvey, handleCommand, viewRespondent, moment
        }
    },
})
</script>
