import { ref } from 'vue'
export default function PexelsSearchImage(http, constant) {
    const pexels_search_loading = ref(false)
    let pexels_list = ref([])

    async function searchPexelsImage(search) {
        try {
            pexels_search_loading.value = true
            pexels_list.value = []

            const f = new URLSearchParams();
            f.append('search', search)

            const response = await http.post(constant.SEARCH_PEXELS_IMAGE, f)
            pexels_search_loading.value = false
            pexels_list.value = response.data

        } catch (err) {
            pexels_search_loading.value = false
        }
    }
    return {
        pexels_list,
        pexels_search_loading,
        searchPexelsImage
    }
}