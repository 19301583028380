<template>
<div>
<div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div class="d-flex align-items-center flex-wrap mr-1">
            <div class="d-flex align-items-baseline flex-wrap mr-5">
                <ul class="breadcrumb pl-0 breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 font-size-sm">
                    <li class="breadcrumb-item">
                        {{survey_list.length}} Total
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="card-toolbar card-actions">
                <a  href="Javascript:void(0)" class="btn font-weight-bolder export-action text-893BF7 mr-3" >
                    <i class="fas fa-file-export icon-md text-893BF7"></i>Export CSV Survey
                </a>
                <a @click="addSurvey"   href="Javascript:void(0)" class="btn font-weight-bolder add-action  class-add-button">
                    <i class=" fas fa-plus-circle icon-md"></i>Add Survey
                </a>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-column-fluid">
    <div class="container">
        <div class="card-header  p-0 pt-0 border-0 bg-none">
                <h3 class="card-title  u-flex">
                    <span class="card-label fw-bolder fs-3 mb-1 u-flex-1 pt-2" id="tabView">
                        <SelectButton v-model="selected_view" :options="view_options" :dataKey="value">
                            <template #option="slotProps">
                                <i :class="slotProps.option.icon"></i>
                            </template>
                        </SelectButton>
                    </span>
                    <div class="p-d-flex p-jc-between me-2 searchbar-width">
                        <span class="p-input-icon-left text-blue">
                            <i class="pi pi-search" />
                            <InputText v-if="selected_view.value == 2" class="tableSearch "  v-model="filter.filter_list['global'].value" placeholder="Search..." />
                            <InputText v-else class="tableSearch" v-model="search" placeholder="Search..." />
                        </span>
                    </div>
                </h3>
        </div>
        <!--
        <div class="card card-custom" v-if="!loading" >
            <div class="card-body flex pb-3">
                <div class="w-100">
                    <h4><a href="Javascript:void(0)" class="text-dark font-size-h4">{{default_survey.name}}</a></h4>
                    <p><a href="Javascript:void(0)" class="grey text-muted">{{default_survey.slug}}</a></p>
                </div>
                <a href="Javascript:void(0)"   class="pt-3" @click="previewDefault()"  title="Preview survey">
                    Preview
                </a>
            </div>
        </div>
        -->
        <div class="card card-custom mt-4" v-if="selected_view.value == 2">
            <div class="card-body">
                 <SurveyTableView :filter="filter" :survey_list="survey_list" :loading="loading" ></SurveyTableView>
            </div>
        </div>
        <div v-else>
            <div v-if="loading" class="text-center align-items-center mt-5 pt-5">
                <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="row" v-if="paginated.length > 0">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(item, i) in paginated" :key="i">
                    <SurveyCardView :item="item" :index="i"></SurveyCardView>
                </div>
                <div class="col-12">
                        <Paginator 
                          v-if="!loading"
                        template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                         :rows="per_page"  @page="paginate($event, filtered_list)" :totalRecords="filtered_list.length" :rowsPerPageOptions="[6, 10,20,30,50,100,1000]"> </Paginator>
                </div>
            </div>
            <div v-else class="pt-4">
                <no-results-error v-if="!loading" :message="`No survey found!`"></no-results-error>
            </div>
        </div>
    </div>
</div>
    <SurveyAddOrEdit :class_list="class_list" :selected_survey="selected_survey" :add_survey_watcher="add_survey_watcher" :type="action_type"></SurveyAddOrEdit>
    <SurveyAddQuestions  :selected_survey="selected_survey" :add_survey_question_watcher="add_survey_question_watcher"></SurveyAddQuestions>
    <SurveyActivate  :add_survey_active_watcher="add_survey_active_watcher" :survey_list="survey_list"></SurveyActivate>
    <SurveyPreviews :selected_survey="selected_survey" :class_list="class_list" :survey_preview_watcher="survey_preview_watcher"></SurveyPreviews>
    <SurveyRespondent :selected_survey="selected_survey" :survey_respondent_watcher="survey_respondent_watcher"></SurveyRespondent>
    <SurveyREport :survey_report_watcher="survey_report_watcher" :selected_survey="selected_survey"  ></SurveyREport>
    <ClassReport  :class_report_watcher="class_report_watcher"  :class_list="class_list"  :source="`survey`" :selected_class="selected_class" :defaultSurvey="selected_survey"></ClassReport>

    <ConfirmDialog></ConfirmDialog>

</div>
</template>
<script>
import  SurveyGetList  from '@/composables/survey/SurveyGetList'
import  SurveyDelete  from '@/composables/survey/SurveyDelete'
import  ClassGetList  from '@/composables/class/ClassGetList'
import  usePagination  from '@/composables/common/usePagination'


import { defineComponent, ref, onMounted, inject, reactive, watch, provide, computed } from 'vue'
import {FilterMatchMode} from 'primevue/api'

import InputText from 'primevue/inputtext'
import { useConfirm } from "primevue/useconfirm"
import SurveyAddOrEdit from './SurveyAddOrEdit.vue';
import SurveyAddQuestions from './SurveyAddQuestions.vue';
import SurveyActivate from './SurveyActivate.vue';
import SurveyPreviews from './SurveyPreviews.vue'
import SurveyRespondent from './SurveyRespondent.vue'
import SelectButton from 'primevue/selectbutton';
import SurveyCardView from './SurveyCardView.vue'
import SurveyTableView from './SurveyTableView.vue'
import SurveyREport from './SurveyReport.vue'

import ConfirmDialog from 'primevue/confirmdialog';
import NoResultsError from '@/components/NoResultsError.vue'
import Paginator from 'primevue/paginator';
import ClassReport from '../class/ClassReport.vue'
import  YearGetList  from '@/composables/year/YearGetList'

export default defineComponent({
    components: {   ClassReport, Paginator, SurveyTableView, SurveyCardView, NoResultsError, SelectButton, InputText,
                    SurveyAddOrEdit, SurveyAddQuestions, SurveyActivate, SurveyPreviews,
                    ConfirmDialog, SurveyRespondent, SurveyREport },
    setup() {
        const confirm = useConfirm();
        const { constants, http, toast, store } = inject('App')
        const { loading, default_survey, survey_list, getSurveyList } = SurveyGetList(http, constants, store)
        const { delete_status, deleteSurvey } = SurveyDelete(http, constants, toast)
        const { class_loading, class_list, getClassList } = ClassGetList(http, constants, store)
        const { activePage, setActivePage,  paginated, paginate } = usePagination()
        const { yearList, getList } = YearGetList(http, constants, store)

        const selected_survey = ref([])
        const is_add = ref(false)
        const add_survey_watcher = ref(1)
        const add_survey_question_watcher = ref(1)
        const add_survey_active_watcher = ref(1)
        const action_type = ref('') 
        const survey_preview_watcher = ref(1)
        const survey_respondent_watcher = ref(1)
        const filter = reactive({
            filter_list: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })

        const selected_view = ref({icon: 'pi pi-th-large', value: 1})

        const view_options = ref([
            {icon: 'pi pi-th-large', value: 1},
            {icon: 'pi pi-table', value: 2},
        ]);
        const page = ref(1)
        const per_page = ref(6)
        const current_page = ref({
            first: 1,
            page: activePage.value,
            pageCount: 6,
            rows: per_page.value
        })
        const search = ref('')
        const survey_report_watcher = ref(1)
        const class_report_watcher = ref(1)

       



        function addSurvey() {
            action_type.value = 'add'
            add_survey_watcher.value++
        }
        function edit(s) {
            selected_survey.value = s
            action_type.value = 'edit'
            add_survey_watcher.value++
        }
        function viewRespondent(s) {
            selected_survey.value = s
            survey_respondent_watcher.value++
        }
        function deletePrompt(q) {
            selected_survey.value = q
            confirm.require({
                message: `Are you sure you want to delete ${ selected_survey.value.name}?`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteNow()
                },
                reject: () => {
                }
            });
            return {deletePrompt};
        }
        function deleteNow() {
            deleteSurvey(selected_survey.value.id)
        }
        function addQuestions(q) {
            add_survey_question_watcher.value++
            selected_survey.value =  q
        }
        function activate() {
            add_survey_active_watcher.value++
        }
        function previewDefault() {
            selected_survey.value = default_survey.value
            survey_preview_watcher.value++
        }
        function copyLink(v) {
            toast.add({severity:'success', summary: 'Success', detail:'Link was successfully copied.', life: 3000});
            navigator.clipboard.writeText(v);
        }
        function previewSurvey(s) {
            selected_survey.value = s
            window.open(`${process.env.VUE_APP_API_SITE}survey/preview/${s.id}/${s.slug}`, '_blank');
        }
        function handleCommand(c, props) {
            if (c.action == 'questions')
                addQuestions(c.data)
            if (c.action == 'edit')
                edit(c.data)
            if (c.action == 'delete')
                deletePrompt(c.data)
            if (c.action == 'respondents')
                viewRespondent(c.data)
            if (c.action == 'report')  {
                survey_report_watcher.value++
                selected_survey.value = c.data
            }
            if (c.action == 'preview')
                previewDefault()
            if (c.action == 'default-report') {
                selected_survey.value = c.data
                class_report_watcher.value++        
            }
        }

        const filtered_list = computed(() => {
        
            return survey_list.value.filter((obj) => {
            return (
                obj.name
                .toLowerCase()
                .indexOf(search.value.toLowerCase()) != -1
            );
            });
        });

        const pageCount = computed(() => {
            let s = filtered_list.value.length / per_page.value
            return s <= 0 ? 1 : Math.floor(s)
        });
        
        watch(search, (n, o) => {
            setActivePage(0)
        });
        
        watch(loading, (n, o) => {
            if (loading.value == false) 
                search.value = '' 
        });

        watch(filtered_list, (n, o) => {
           current_page.value = {
                    first: 0,
                    page: activePage.value,
                    rows: current_page.value.rows,
                    pageCount: filtered_list.value.length
            }
            paginate(current_page.value, filtered_list.value)
        });

        watch(delete_status, (n, o) => {
            if (delete_status.value) {
                let index = survey_list.value.findIndex(obj => obj.id === selected_survey.value.id)
                survey_list.value.splice(index, 1)
            }
        });

        onMounted(() => {
            getList()

            getSurveyList()
            getClassList()
        })
        provide('SurveyParent', { previewDefault, viewRespondent, getSurveyList, copyLink, previewSurvey,
                                    handleCommand, filter : filter.filter_list['global'] 
                                })

        return {
            class_list,
            user: computed(() => store.getters['UserModule/GET_USER']),
            selected_survey, is_add, filter, survey_list,  loading, add_survey_watcher, action_type,
            addSurvey, deletePrompt, edit,
            add_survey_active_watcher,
            getSurveyList, addQuestions,
            add_survey_question_watcher,
            activate,
            default_survey, survey_preview_watcher,
            previewDefault, previewSurvey, viewRespondent, survey_respondent_watcher,
            web_url: `${process.env.VUE_APP_API_SITE}${constants.SURVEY_THUMBNAIL_PATH}`,
            copyLink, ënv: process.env, handleCommand,
            selected_view, view_options, search, survey_report_watcher,
            paginate,paginated, filtered_list, current_page, per_page, page, pageCount,
            class_report_watcher
        }
    },
})
</script>
