<template>
    <VueApexCharts width="600px" type="line" :height="400" :options="options" :series="series"></VueApexCharts>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
    components: { VueApexCharts },
    props: {
        refresh_line_chart: {
            default: 1,
            type: Number
        },
        series: {
            default: []
        },
        categories: {
            default: []
        },
    },
    setup(props) {
         let options = ref({
                chart: {
                    id: 'vueline-example',
                    toolbar: {
                        show: false
                    },
                    height: '100%',
                },
                xaxis: {  
                    categories: ['True', 'False']
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                           return `${val}`
                        }
                    }
                },
                stroke: {
                    curve: 'straight',
                }
        })
        function setOptions() {
             setTimeout(() => {
                 options.value = {
                    xaxis: {
                        categories: props.categories
                    }
                }     
            }, 300);
        }

        watch(()=>props.refresh_line_chart, (n) => {
           setOptions()
           
        })  
         onMounted(() => {
             setOptions()
         })
        return {
            options
        }
        
    },
})
</script>
