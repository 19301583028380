<template>
    <div class="card card-custom gutter-b card-stretch">
        <div class="card-body pt-4">
            <div class="d-flex justify-content-end">
                <div class="dropdown dropdown-inline show" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                                <span class="svg-icon svg-icon-primary svg-icon-md">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1">
                                        <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                        <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu v-if="item.deletable == 1">
                                <el-dropdown-item   :command="{ action: 'edit', data: item}" ><i class="pi pi-pencil text-primary"></i> Edit Survey </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'questions', data: item}"><i class="pi pi-question text-primary"></i> Update Questions</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'respondents', data: item}"><i class="pi pi-users text-primary"></i> Survey Respondents</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'report', data: item}"><i class="pi pi-chart-bar text-primary"></i>  Reports</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'delete', data: item}"><i class="pi pi-trash text-primary"></i>  Delete Survey</el-dropdown-item>
                            </el-dropdown-menu>
                            <el-dropdown-menu v-else>
                                <el-dropdown-item :command="{ action: 'preview', data: item}"><i class="pi pi-search text-primary"></i>  Preview</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'default-report', data: item}"><i class="pi pi-chart-bar text-primary"></i>  Reports</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="d-flex align-items-center mb-7">
                <div class="flex-shrink-0 mr-4">
                    <div class="symbol symbol-circle symbol-lg-75">
                        <img v-if="item.thumbnail_type == 'image'
                                    || item.thumbnail_type == 'upload' 
                                    || item.thumbnail_type == 'library'"  :src="`${web_url}${item.thumbnail}`" alt="image">
                        <div  v-if="item.thumbnail_type == 'color'" :style="{ 'background-color': `#${item.thumbnail_bg}`, height: '50px', width: '50px', 'border-radius': '50%'}"></div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <a href="#" class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{{ item.name }}</a>
                    <span class="text-muted font-weight-bold">{{ item.slug }}</span>
                </div>
            </div>
            <p class="mb-7">{{ item.descriptions }}</p>
            <div v-if="item.deletable == 1">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="text-dark-75 font-weight-bolder mr-2"># of Respodents:</span>
                    <a href="Javascript:void(0)" @click="viewRespondent(item)" class="text-muted text-hover-primary">{{ item.respondent}}</a>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex align-items-sm-center flex-wrap flex-column flex-sm-row" style="padding: 1rem 2.25rem">
             <div class="d-flex">
                <div class="d-flex mr-7">
                    <p class="text-muted pt-2" >{{ moment(item.created_at).format('LL') }}</p>
                </div>
            </div>
            <a v-if="item.deletable == 1" href="Javascript:void(0)"  @click="copyLink(`${ënv.VUE_APP_API_SITE}survey/${item.id}/${item.slug}`)"  class="btn btn-light btn-sm   mt-sm-0 mr-auto mr-sm-0 ml-sm-auto">Copy Link</a>
            <a v-if="item.deletable == 1"  href="Javascript:void(0)" @click="previewSurvey(item)"  class="btn btn-light btn-sm  mt-sm-0 mr-auto mr-sm-0 ml-sm-auto">Preview Survey</a>
        </div>
    </div>
</template>
<script>
import { defineComponent, inject } from 'vue'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'
import moment from 'moment'

export default defineComponent({
    props: {
        item: {
            default: []
        },
        index: {
            default: 0,
            type: Number
        }
    },
    components: { ElDropdown, ElDropdownItem, ElDropdownMenu }, 
    setup() {
        const { constants } = inject('App')
        const { copyLink, previewSurvey, handleCommand, viewRespondent } = inject('SurveyParent') 
        return {
            web_url: `${process.env.VUE_APP_AWS_URL}`,
            ënv: process.env, copyLink, previewSurvey, handleCommand, viewRespondent, moment
        }
    }
})
</script>
