import { ref } from 'vue'
export default function usePagination() {
    const paginated = ref([])
    const activePage = ref(0)
    function paginate(page, filtered_list) {
        paginated.value = []
        let p = page.page
        if (p == 0)
          p = 1
      
        let add_on = 0

        activePage.value = page.page

        if (page.page > 0)
          add_on = page.rows

        let first = (page.rows * (p - 1))  +  add_on
        let second = (page.rows * p ) + add_on

        paginated.value = filtered_list.slice(first, second)
    }
    function setActivePage(p) {
      activePage.value =  p
    }
    return {
        activePage, setActivePage, paginated, paginate
    }
}