<template>
    <VueApexCharts  width="600px" type="pie" :height="400" :options="options" :series="series"></VueApexCharts>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
    components: { VueApexCharts },
    props: {
        refresh_pie_chart: {
            default: 1,
            type: Number
        },
        series: {
            default: []
        },
        labels: {
            default: []
        }
    },
    setup(props) {
        let options = ref({
            chart: {
                toolbar: {
                    show: false
                },
            },
            labels: []
        })

        function setOptions() {
            setTimeout(() => {
                options.value = {
                    labels: props.labels
                }
            }, 300);
        }

        watch(()=>props.refresh_pie_chart, (n) => {
            setOptions()
        })  
        
        onMounted(() => {
           setOptions()
        })
        
        return {
            options
        }
        
    },
})
</script>
