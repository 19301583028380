<template>
   <Sidebar  v-model:visible="visible" position="right" class="p-sidebar-lg"  :dismissable="true" :modal="true" :showCloseIcon="false" >
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Survey Respondent</p>
                    <small >{{ selected_survey.name }}</small>
                </div>
                <span @click="visible = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
    <div class="drawer-content">
	<div class=" pt-5 pb-10 mb-10 p-10" >
            <DataTable  class="p-datatable-lg" ref="dt"  :value="survey_respodent_list"
                        dataKey="id" v-model:filters="filter.filter_list"  filterDisplay="menu"  :loading="respondent_loading" responsiveLayout="stack" breakpoint="960px"
					   	:paginator="true" :rows="10"
                        :resizableColumns="true" columnResizeMode="fit"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,20,50]">
                            <template #header>
                            <div class="card-header  p-0 pt-0 border-0">
                                <h3 class="card-title  u-flex">
                                    <span class="card-label fw-bolder fs-3 mb-1 u-flex-1"></span>
                                    <div class="p-d-flex p-jc-between me-2">
                                        <span class="p-input-icon-left text-blue">
                                            <i class="pi pi-search" />
                                            <InputText class="tableSearch" v-model="filter.filter_list['global'].value" placeholder="Search..." />
                                        </span>
                                    </div>
                                    <a href="Javascript:void(0)" @click="getSurveyRespondent(selected_survey.id)" class="ml-2 btn btn-sm btn-light-primary fw-600 me-2">
                                        <i class="pi pi-search  search-icon" />
                                    </a>
                                </h3>
                                  
                            </div>
                            </template>
							<Column field="fname" header="Name" :sortable="true">
                                 <template #body="slotProps">
                                     <a href="Javascript:void(0)"  @click="viewResults(slotProps.data)">
                                       {{ slotProps.data.fname }} {{ slotProps.data.lname }}
                                    </a>
                                </template>
                            </Column>
							
                            <Column field="email" header="Email" :sortable="true">
                                <template #body="slotProps">
                                     <a href="Javascript:void(0)"  @click="viewResults(slotProps.data)">
                                       {{ slotProps.data.email }}
                                    </a>
                                </template>
                            </Column>
							<Column field="phone" header="Contact Number" :sortable="true"></Column>
							<Column field="created_at" header="Submitted At" :sortable="true">
                                <template #body="slotProps">
                                       {{ moment(slotProps.data.created_at).format('YYYY-MM-DD hh:mm a') }}
                                </template>
                            </Column>
                            <template #loading>
                                Loading  data. Please wait.
                            </template>
                            <template #empty>
                                No respondent found.
                            </template>
						</DataTable>
    </div>
    </div>
  </Sidebar>
  <SurveyResults :selected_survey="selected_survey" :selected_respondent="selected_respondent" :survey_respondent_results_watcher="survey_respondent_results_watcher"></SurveyResults>
</template>
<script>
import Sidebar from 'primevue/sidebar';
import { defineComponent, ref, watch, inject, reactive } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import  SurveyGetRespodent  from '@/composables/survey/SurveyGetRespodent'
import moment from 'moment'
import SurveyResults from './SurveyResults.vue'

export default defineComponent({
    components: { Sidebar, DataTable, Column, InputText, SurveyResults },
    props: {
        survey_respondent_watcher: {
        default: 1,
            type: Number
        },
        selected_survey: {
            default: []
        }
    },
    setup(props) {
        const { http, constants } = inject('App')

        const visible = ref(false)
        const filter = reactive({
            filter_list: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })
        const survey_respondent_results_watcher = ref(1)
        const selected_respondent = ref([])
        const { respondent_loading, survey_respodent_list, getSurveyRespondent } = SurveyGetRespodent(http, constants)

        function viewResults(r) {
            selected_respondent.value = r
            survey_respondent_results_watcher.value++
        }    
        watch(()=>props.survey_respondent_watcher, (n) => {
            visible.value = true
            getSurveyRespondent(props.selected_survey.id)
        })

        return {
            visible, survey_respodent_list, filter, respondent_loading, moment, getSurveyRespondent,
            viewResults, selected_respondent, survey_respondent_results_watcher
        }
    },
})
</script>
