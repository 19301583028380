<template>
    <Sidebar v-model:visible="visible" position="right" class="p-sidebar-md" :dismissable="true" :modal="true"
        :showCloseIcon="false">
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Survey Preview</p>
                    <small>{{ selected_survey.name }}</small>
                </div>
                <span @click="visible = false" class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content pt-2">
            <div class=" pt-5 pb-10 mb-10 p-10">
                <div class="form-group pt-3">
                    <label>Select Class <span class="text-danger">*</span></label>
                    <select class="form-control" name="class" v-model="selectedClass">
                        <option value="">Select</option>
                        <option v-for="(item, i ) in class_list" :key="i" :value="item">{{item.name}}</option>
                    </select>
                </div>
                <fieldset>
                    <div v-for="(item, i) in default_survey.questionslist" :key="i"
                        class="box-shadow rounded-lg mb-5 pt-10 pb-5">

                        <p class="fw-500"> {{i + 1}}. {{ prepare(item.question) }} </p>

                        <!-- <p class="fw-500" v-if="i == 1">{{ i + 1 }}. Is there something <span color="tertiary"
                                class="fw-600 "> Josh </span> is looking forward to?</p> -->

                        <section class="pl-5 pr-5 pt-5 pb-5" v-if="i == 0">
                            <div class="mb-2" v-for="(student, x) in active_student_list" :key="x">
                                <div class="d-flex">
                                    <Checkbox :value="student" class="pt-3" v-model="selected_students" />
                                    <Avatar v-if="student.thumbnail_type == 'image'
                                    || student.thumbnail_type == 'upload' 
                                    || student.thumbnail_type == 'library' "
                                        :image="`${student.path}${student.thumbnail}`" class="p-mr-2 ml-4" size="large"
                                        shape="circle" />
                                    <div v-if="student.thumbnail_type == 'color'"
                                        :style="{ 'background-color': `#${student.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"
                                        class="ml-4"></div>

                                    <p class="pl-5 pt-2 text-capitalize">{{student.name}} </p>
                                </div>
                            </div>
                        </section>

                        <section class="pl-5 pr-5 pt-5 pb-5" v-if="item.question_type == 6">
                            <div class="mb-0" v-for="(row, x) in item.choices " :key="x">
                                <div class="d-flex pb-3">
                                    <label class="radio radio-lg">
                                        <input type="radio" checked="checked" name="radios3_1" />
                                        <span class="mr-3"></span>
                                        {{row.options}}
                                    </label>
                                </div>
                            </div>
                        </section>

                        <section class="pl-5 pr-5 pt-5 pb-5" v-if="item.question_type == 1 && i == 2">
                            <div class="mb-0 pb-0" v-for="(row, x) in item.choices" :key="x">
                                <div class="d-flex pb-3">
                                    <label class="radio radio-lg">
                                        <input type="radio" checked="checked" name="radios3_2" />
                                        <span class="mr-3"></span>
                                        {{row.options}}
                                    </label>
                                </div>
                            </div>
                        </section>

                        <section class="pl-5 pr-5 pt-5 pb-5" v-if="item.question_type == 1 && i == 3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="mb-0 pb-0">
                                        <div class="row">
                                            <template v-for="(row, x) in item.choices" :key="x">
                                                <div class="col-12 col-md-4 d-flex pt-5" v-if="x == 3">
                                                    <div style="padding-top: 12px;padding-right: 12px;">
                                                        <RadioButton id="city23" name="choices4" />
                                                    </div>
                                                    <img src="@/assets/images/sad.png" style="width: 40px;height: 40px">
                                                </div>

                                                <div class="col-12 col-md-4 d-flex pt-5" v-if="x == 6">
                                                    <div style="padding-top: 12px;padding-right: 12px;">
                                                        <RadioButton id="city23" name="choices4" />
                                                    </div>
                                                    <img src="@/assets/images/neutral.png"
                                                        style="width: 40px;height: 40px">
                                                </div>

                                                <div class="col-12 col-md-4 d-flex pt-5" v-if="x == 9">
                                                    <div style="padding-top: 12px;padding-right: 12px;">
                                                        <RadioButton id="city23" name="choices4" />
                                                    </div>
                                                    <img src="@/assets/images/smiling-face.png"
                                                        style="width: 40px;height: 40px">
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </fieldset>
            </div>
        </div>
    </Sidebar>
</template>
<script>
import Sidebar from 'primevue/sidebar';
import { defineComponent, inject, ref, watch } from 'vue'
import Avatar from 'primevue/avatar';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import SurveyGetDefault from '@/composables/survey/SurveyGetDefault'

export default defineComponent({
    components: { Sidebar, Avatar, Checkbox },
    props: {
        survey_preview_watcher: {
            default: 1,
            type: Number
        },
        selected_survey: {
            default: []
        },
        class_list: { default: [] }
    },
    methods: {
        prepare(question) {
            question = question.replace('{{ Student Name }}', 'Josh')
            return question;
        }
    },
    setup(props) {
        const visible = ref(false)
        const selected = ref([])
        const yesno_selected = ref()

        const { http, constants } = inject('App')
        const { sloading, default_survey, student_list, getDefaultSurvey } = SurveyGetDefault(http, constants)


        const selectedClass = ref([])
        const active_student_list = ref([])
        const selected_students = ref([])
        const choices2 = ref('')
        const choices3 = ref('')

        watch(selectedClass, (n, o) => {
            getDefaultSurvey(0, selectedClass.value.id, 'Term 1', 'Week 1')
        });

        watch(sloading, (n, o) => {
            if (sloading.value == false) {
                active_student_list.value = student_list.value
            }
        });

        watch(() => props.survey_preview_watcher, (n) => {
            visible.value = true
        })

        return {
            visible, selected, yesno_selected, selectedClass, default_survey, student_list, sloading,
            web_url: `${process.env.VUE_APP_API_SITE}${constants.STUDENTS_THUMBNAIL_PATH}`,
            active_student_list, selected_students, choices2, choices3
        }
    },
})
</script>
