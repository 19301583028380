import { ref } from 'vue'
export default function SurveyGetStudentByAnswer(http, constant) {
    let survey_student_loading = ref(false)
    let survey_student_list = ref([])

    async function getSurveyStudentListByAnswer(class_id, term, week_no, student_id, question_id) {
        survey_student_loading.value = true
        survey_student_list.value = []
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('term', term)
            f.append('week_no', week_no)
            f.append('student_id', student_id)
            f.append('question_id', question_id)
            const response = await http.post(constant.SURVEY_GET_STUDENT_LIST_BY_ANSWERS, f)
            survey_student_loading.value = false
            survey_student_list.value = response.data.student_list
        } catch (err) {
            survey_student_loading.value = false
        }
    }
    return {
        survey_student_loading, survey_student_list, getSurveyStudentListByAnswer
    }
}