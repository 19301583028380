<template>
<Sidebar header="Crop Image" :modal="true" v-model:visible="display" position="full" :showCloseIcon="false" >
    <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Image Library</p>
                    <small>{{type}}</small>
                </div>
                <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content">
            <div class="container">
                <div class=" pt-5 pb-10 mb-10 p-10 row" >
                        <div class=" cursor-pointer col-12 col-sm-6 col-md-3  pb-4" v-for="(item, i) in image_library_list" :key="i">
                            <img :src="`${item.path}${item.thumbnail}`" @click="selectImage(item)" class="w-100" style="border-radius: 5px" alt="...">
                        </div>
                </div>
            </div>
        </div>
    <template #footer>
    </template>
</Sidebar>
</template>
<script>
import { defineComponent, computed, watch, inject, ref, provide} from 'vue'
import Sidebar from 'primevue/sidebar';
import  ImageLibraryGetImages  from '@/composables/image-library/ImageLibraryGetImages'
export default defineComponent({
    components: { Sidebar },
      props: {
        image_library_watcher: {
            type: Number,
            default: 1
        },
        thumbnail_type: {
            type: String,
            default: 'thumbnail'
        },
        type: {
            type: String,
            default: 'user_library_thumb'
        },
        path: {
            type: String,
            default: 'thumbnail/students',
        },
        modules: {
            type: String,
            default: 'UserModule/SET_USER_LIBRARY_THUMB'
        }
    },
    setup(props,  { root }) {
        const { constants, store, http } = inject('App')

        const display = ref(false)
        const selected_image = ref([])
        const { image_library_list, image_library_loading, getImageLibrary } = ImageLibraryGetImages(http, constants)

        function selectImage(data) {
            store.commit(props.modules, data)
            display.value = false
        }
        watch(()=>props.image_library_watcher, (n) => {
            display.value = true
            getImageLibrary(props.thumbnail_type, props.path)
        })
        return {
            display, image_library_list, image_library_loading,
            selectImage,  selected_image   
        }
    },
})
</script>
