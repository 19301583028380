<template>
    <VueApexCharts width="600px" type="bar"  ref="chart" :height="400" :options="options" :series="series"></VueApexCharts>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
    components: { VueApexCharts },
    props: {
        refresh_column_chart: {
            default: 1,
            type: Number
        },
        series: {
            default: []
        },
        categories: {
            default: []
        },
        is_horizontal: {
            default: false,
            type: Boolean
        }
    },
    setup(props) {
        const chart = ref()
        let options = ref({
                chart: {
                    id: 'vuebar-example',
                    toolbar: {
                        show: false
                    },
                    height: '100%',
                },
                xaxis: {  
                    categories: []
                },
                yaxis: {
                    min: 0,
                    tickAmount: 5
                },
                 plotOptions: {
                    bar: {
                        distributed: true,
                        horizontal: false,
                        columnWidth: '10%',
                        endingShape: 'rounded'
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                           return `${val}`
                        }
                    }
                }
        })
        
        function setOptions() {
            setTimeout(() => {
                options.value = {
                    xaxis: {
                            categories: props.categories
                    },
                    plotOptions: {
                        bar: {
                            horizontal: props.is_horizontal
                        }
                    }
                }
             }, 300);
        }
        
        watch(()=> (props.refresh_column_chart) , (n) => {
             setOptions()
        })
        
        onMounted(() => {
            setOptions()
        })

        return {
            options
        }
    },
})
</script>
