import { ref } from 'vue'
export default function SurveyUpdateQuestions(http, constant, toast) {
    const create_status = ref(false)
    function getIDs(questions_list) {
            let ids = ''
            questions_list.forEach((obj) => {
              if (!ids) {
                ids += `${obj.id}`
                 
              } else {
                ids += `,${obj.id}`
              }
            });
            return ids;
    }
    async function updateSurveyQuestions(id, questions_list) {
        try {
            create_status.value = true



            const f = new FormData();
            f.append('id', id)
            f.append('questions_list', getIDs(questions_list))

            const response = await http.post(constant.SURVEY_UPDATE_QUESTIONS, f)
            create_status.value = false

            toast.add({severity:'success', summary: 'Success', detail:'Survey questions was successfully updated.', life: 3000});

        } catch (err) {
            create_status.value = false
        }
    }
    return {
        create_status,
        updateSurveyQuestions
    }
}