<template>
<Sidebar  v-model:visible="display" position="full"  :dismissable="false" :modal="true" :showCloseIcon="false" >
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Survey</p>
                    <small>Survey Report</small>
                </div>
                <div  style="width: 150px !important">
                    <a href="Javascript:void(0)" class="btn btn-light-primary mr-10" @click="generateReport">Print</a>
                    <span @click="display = false"  class="cursor-pointer fs-20 text-red"><i class="pi pi-times text-danger"></i></span>
                </div>
            </div>
        </div>
        <div class="drawer-content">
        <div class="container" v-if="!report_loading">
            <vue3-html2pdf
                    :show-layout="true"
                    :float-layout="false"
                    :enable-download="false"
                    :preview-modal="true"
                    filename="class-report-breakdown"   
                    :manual-pagination="true"
                    pdf-orientation="portrait"
                    pdf-content-width="100%"
                    ref="html2Pdf"
                    :html-to-pdf-options="pdfOptions">
            <template v-slot:pdf-content>
                <section>
                    <div class="row" >
                        <div class="col-12 col-md-12">
                            <div  class="p-10 pt-0 mt-0">
                                <h4>{{ survey_report.survey.name }}</h4>
                                <p>{{ survey_report.survey.descriptions }}</p>
                                <div v-for="(item, i) in survey_report.question_list" :key="i" class="mb-5 mt-5">
                                    <div class="box-shadowv1 p-10 b-r-10  mt-2">
                                        <p class="fs-16 fw-600">{{i + 1}}.   {{item.question}}</p>
                                        <div class="flex mt-5">
                                            <p class="fw-500 text-success">  Answered: {{ item.answered}}</p>
                                            <p class="ml-5 pl-5 fw-500  text-danger">Skipped: {{ item.skipped}}</p>
                                        </div>
                                    
                                        <template v-if="item.question_type != 3 && item.question_type != 5" >
                                            <PieChart v-if="item.chart_type == 1"  :refresh_pie_chart="1" :series="item.series" :labels="item.categories"></PieChart>
                                            <ColumnChart v-if="item.chart_type == 2 || item.chart_type == 5" :is_horizontal="item.chart_type == 5 ? true : false"  :refresh_column_chart="1" :series="item.series" :categories="item.categories" ></ColumnChart>
                                            <AreaChart v-if="item.chart_type == 3"  :refresh_area_chart="1" :series="item.series"  :categories="item.categories" ></AreaChart>
                                            <LineChart v-if="item.chart_type == 4"  :refresh_line_chart="1" :series="item.series"  :categories="item.categories" ></LineChart>
                                        </template>

                                        <template v-if="item.question_type == 3 || item.question_type == 5">
                                                <div v-for="(answers, x) in item.answers" :key="x" class="box-bordered-bottom mt-2 no-shadow shadow-none">

                                                    <p v-if="answers.question_type == 1 || answers.question_type == 2 || answers.question_type == 6"><span class="fw-600">{{ x + 1}}.</span> {{answers.optionname.options}}</p>
                                                    <p v-else><span class="fw-600">{{ x + 1}}.</span> {{answers.answer_text}}</p>
                                                </div>
                                        </template><br><br>
                                        <template v-if="item.question_type != 3 && item.question_type != 5">
                                                <table class="table table-hover">
                                                    <tr class="table-primary">
                                                        <td class="font-weight-bold">Choices</td>
                                                        <td class="font-weight-bold">Responses</td>
                                                        <td></td>
                                                    </tr>
                                                    <tbody>
                                                        <tr v-for="(choices, z) in item.choices" :key="z">
                                                            <td>{{ choices.options }}</td>
                                                            <td>{{ ((choices.count / item.answered) * 100).toFixed(2) }} %</td>
                                                            <td>{{ choices.count }}</td>
                                                        </tr>
                                                        <tr class="table-primary">
                                                            <td class="font-weight-bold">Total</td>
                                                            <td></td>
                                                            <td class="font-weight-bold">{{ item.answered }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                        </template>
                                    </div>
                                    <div class="html2pdf__page-break"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  class="p-10 pt-0 mt-0  pt-0 mt-0">
                        <div class="row ">
                            <div class="col-12 col-md-12">
                                <div class="row pl-4 pr-4">
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <SurveyReportCard :value="survey_report.survey.opened" :title="`Page Views`"></SurveyReportCard>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <SurveyReportCard :value="survey_report.respodent_count" :title="`Total Response`"></SurveyReportCard>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <SurveyReportCard :value="`${((survey_report.respodent_count /  survey_report.survey.opened) * 100).toFixed(2) } %`"  :title="`Average Completion rate`"></SurveyReportCard>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <SurveyReportCard :value="`${(survey_report.average)} minutes`" :title="`Time Spent`"></SurveyReportCard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            </vue3-html2pdf>
        </div>
        </div>
     </Sidebar>
</template>

<script>
import { defineComponent, ref, watch, inject } from 'vue'
import Sidebar from 'primevue/sidebar'
import  SurveyGetReports  from '@/composables/survey/SurveyGetReports'
import PieChart from '@/components/charts/PieChart.vue'
import ColumnChart from '@/components/charts/ColumnChart.vue'
import SurveyReportCard from './SurveyReportCard.vue'
import AreaChart from '@/components/charts/AreaChart.vue'
import LineChart from '@/components/charts/LineChart.vue'
import Vue3Html2pdf from 'vue3-html2pdf'

export default defineComponent({
    components: { Sidebar, PieChart, ColumnChart, SurveyReportCard, AreaChart, LineChart, Vue3Html2pdf },
    props: {
         survey_report_watcher: {
            type: Number,
            default: 1
        },
        selected_survey : {
            default: []
        },
    },
    setup(props) {
        const { http, constants } = inject('App')
        const display = ref(false)

        const { report_loading, survey_report, getSurveyReport } = SurveyGetReports(http, constants)

         let options = ref({
                chart: {
                    toolbar: {
                        show: false
                    },
                }
        })

        const series = ref([1,2])
        const labels = ref(['True', 'False'])
        const chart = ref()
        const html2Pdf = ref()

        function setoptions(item) {
            return options.value = {
                labels: item.categories
            }
        }

        const pdfOptions = ref(
          {
            margin: 5,
            image: {
                type: 'jpeg',
                quality: .95,
            },
          }
        )

        function generateReport () {
            html2Pdf.value.generatePdf()
        }

        watch(()=>props.survey_report_watcher, (n) => {
            display.value = true
            getSurveyReport(props.selected_survey.id)
       
        })  

       return {
           display, survey_report, options, series, labels, setoptions, report_loading,
           pdfOptions, generateReport, html2Pdf
       }
    },
})
</script>
