import { ref } from 'vue'
export default function SurveyCreate(http, constant, toast) {
    const create_status = ref(false)
    function getIDs(questions_list) {
        let ids = ''
        if (questions_list.length > 0) {
            questions_list.forEach((obj) => {
            if (!ids) {
                ids += `${obj.id}`
                
            } else {
                ids += `,${obj.id}`
            }
            });
        }
        return ids;
    }
    async function createSurvey(id, type, surveyData, thumbnail_color, thumbnail_type, question_flow, is_optin,
                optin_fields, thankyou_type, questions_list, survey_thumb, survey_social_thumb, via_student_id, send_copy, theme_color, text_color) {
        try {
            create_status.value = true

            const f = new FormData(surveyData);
            f.append('thumbnail_color', thumbnail_color)
            f.append('thumbnail_type', thumbnail_type)
            f.append('question_flow', question_flow)
            f.append('is_optin', (is_optin) ? 1: 0)
            f.append('optin_fields', optin_fields)
            f.append('thankyou_type', thankyou_type)
            f.append('survey_thumb', survey_thumb)
            f.append('social_thumb', survey_social_thumb)
            f.append('via_student_id', (via_student_id) ? 1 : 0)
            f.append('send_copy', (send_copy) ? 1: 0)
            f.append('theme_color', theme_color)
            f.append('text_color', text_color)
            f.append('questions_list', getIDs(questions_list))
            if (type  == 'edit')  
                f.append('id', id)

            const response = await http.post(type  == 'edit' ? constant.SURVEY_EDIT :  constant.SURVEY_CREATE, f)
            create_status.value = false
            toast.add({severity:'success', summary: 'Success', detail:'Survey was successfully created/updated.', life: 3000});

        } catch (err) {
            console.log(err.message)
            create_status.value = false
        }
    }
    return {
        create_status,
        createSurvey
    }
}