<template>
<div>
<nav aria-label="breadcrumb"  class="pb-3">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ selectedClass.name }}</a></li>
        <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ selectedYear }}</a></li>
        <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ active_term }}</a></li>
        <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ selected_week }}</a></li>
        <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ selected_survey.name }}</a></li>
    </ol>
</nav>

   <p  class=" fs-16 fw-600 pb-0 mb-0 pb-0">1. Children at risk of disconnection or feeling isolated:  {{ active_term }} - {{ selected_week }}</p>
    <div class="p-10">
        <section class="mb-1  pb-1 cursor-pointer" v-for="(student, x) in survey_report.lowest" :key="x"  v-on:click="viewStudents(student, i)">
            <section class="d-flex student-cardv2">
                <!-- <Avatar :image="`${web_url}${student.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" /> -->
                <span class="pl-5 pt-3 pb-2 flex w-100">
                    <p class="pb-0 mb-0 fs-13   text-capitalize  w-100">{{student.name}}</p>
                    <p class="pr-3 pb-0 mb-0"></p>
                </span>
            </section>
        </section>
    </div>

    <p  class="pt-10 fs-16 fw-600 pb-0">2. Children who selected NO positive future focus,  {{ active_term }} - {{ selected_week }}</p>
        <div class="p-10 pt-0">
        <section class="mb-1  pb-1 cursor-pointer" v-for="(student, x) in survey_report.no_list" :key="x"  v-on:click="viewStudents(student, i)">
            <section class="d-flex student-cardv2">
                   <!--  <Avatar :image="`${web_url}${student.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" /> -->
                <span class="pl-5 pt-3 pb-2 flex w-100">
                    <p class="pb-0 mb-0 fs-13   text-capitalize  w-100">{{student.name}}</p>
                </span>
            </section>
        </section>
    </div>
    <p class="pt-10 fs-16 fw-600 pb-10">3. Children responses to feeling unsafe, {{ active_term }} - {{ selected_week }} </p>
    <section>
        <span  v-for="(opt, x) in survey_report.question_three" :key="x">
            <template v-if="opt.options == 'Often' || opt.options == 'Mostly'">
                <p v-if="opt.students.length > 0" class="pl-10 fs-14">Students who selected {{ opt.options }}</p>
                <div v-if="opt.students.length > 0" class="p-10">
                    <section class="mb-1  pb-1 cursor-pointer"  v-for="(student, z) in opt.students" :key="z">
                        <section class="d-flex student-cardv2">
                              <!--   <Avatar :image="`${web_url}${student.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" /> -->
                            <span class="pl-5 pt-3 pb-2 flex w-100">
                                <p class="pb-0 mb-0 fs-13   text-capitalize  w-100">{{student.name}}</p>
                            </span>
                        </section>
                    </section>
                </div>
            </template>
        </span>
    </section> 

    <p class="pt-10 fs-16 fw-600 pb-10">4. List of children who reported feeling sad, {{ active_term }} - {{ selected_week }} </p>
    <section class="pl-10 pr-10">
            <section class="mb-1  pb-1 cursor-pointer"  v-for="(student, z) in survey_report.question_four" :key="z">
                <section class="d-flex student-cardv2">
                        <!--   <Avatar :image="`${web_url}${student.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" /> -->
                    <span class="pl-5 pt-3 pb-2 flex w-100">
                        <p class="pb-0 mb-0 fs-13   text-capitalize  w-100">{{student.name}}</p>
                    </span>
                </section>
            </section>
    </section> 
</div>
</template>
<script>
import { defineComponent, inject } from 'vue'
import Avatar from 'primevue/avatar';

export default defineComponent({
    components: {  },
    props: {
        active_term: { default: '', type: String },
        selected_week: { default: '', type: String },
        survey_report: { default: [] },
        selectedClass: { default: [] },
        selected_survey: { default: [] },
        selectedYear: { default: '' }
    },
    setup() {
        const { constants } = inject('App')
        return {
              web_url: `${process.env.VUE_APP_API_SITE}${constants.STUDENTS_THUMBNAIL_PATH}`,
        }
    },
})
</script>
