import { ref } from 'vue'
export default function QuestionsGetList(http, constant, store) {
    let loading = ref(false)
    let question_list = ref([])

    async function getQuestionsList() {
        loading.value = true;
        try {
            const response = await http.post(constant.QUESTIONS_LIST, [])
            loading.value = false
            question_list.value = response.data.data
            store.commit('QuestionsModule/SET_QUESTION_LIST', question_list.value)

        } catch (err) {
            loading.value = false;
        }
    }
    return {
        loading, question_list, getQuestionsList
    }
}