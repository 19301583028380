<template>
<Sidebar  :modal="true" v-model:visible="display" position="full" :showCloseIcon="false">
    <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Free Images</p>
                    <small>Select free images</small>
                </div>
                <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
    </div>
    <div class="drawer-content">
        <div class="container">
            <div class="form-group pt-4">
                <label>Search</label>
                <div class="row">
                    <div class="col-4">
                        <input type="text" class="form-control"  v-model="search" placeholder="Search image..."/>
                    </div>
                    <div class="col-2">
                        <button type="button" @click="searchImage" :disabled="pexels_search_loading"    class="btn btn-sm btn-info save mr-2">Search</button>
                    </div>
                </div>
            </div>
            <div class=" pt-5 pb-10 mb-10" >
                <div class="card-columns">
                    <div class="card cursor-pointer" v-for="(item, i) in pexels_list.photos" :key="i">
                        <img :src="item.src.medium" @click="selectImage(item)" class="card-img-top" alt="...">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
    </template>
</Sidebar>
<ImageCropper :modules="modules" :selected_image="selected_image" :image_type="`link`" :ratioW="ratioW" :ratioH="ratioH"  :source="source" :image_cropper_watcher="image_cropper_watcher"></ImageCropper>
</template>
<script>
import { defineComponent, computed, watch, inject, ref, provide} from 'vue'
import Sidebar from 'primevue/sidebar';
import  PexelsSearchImage  from '@/composables/image-library/PexelsSearchImage'
import ImageCropper from './ImageCropper.vue';

export default defineComponent({
    components: { Sidebar, ImageCropper },
      props: {
        pexels_image_watcher: {
            type: Number,
            default: 1
        },
        source: {
            type: String,
            default: 'survey_thumb'
        },
        modules: {
            type: String,
            default: 'SurveyModule/SET_SURVEY_THUMB'
        }
    },
    setup(props,  { root }) {
        const { constants, http} = inject('App')

        const display = ref(false)
        const search = ref('')
        const selected_image = ref([])
        const image_cropper_watcher = ref(1)
        const { pexels_list, pexels_search_loading, searchPexelsImage } = PexelsSearchImage(http, constants)
        const ratioW = ref(720)
        const ratioH = ref(720)
        function searchImage() {
            searchPexelsImage(search.value)
        }
        function selectImage(i) {
            console.log(i)
            selected_image.value = i
            image_cropper_watcher.value++
        }
        function closeDialog() {
            display.value = false
        }
        watch(()=>props.pexels_image_watcher, (n) => {
            display.value = true
            if (props.source == 'student_thumb' || props.source == 'user_thumb' || props.source == 'class_thumb' || props.source == 'survey_thumb') {
                ratioW.value = 120
                ratioH.value = 120
            }
        })

        provide('CropperParent', { closeDialog })

        return {
            ratioW, ratioH,
            display, searchPexelsImage, pexels_search_loading, search, searchImage, pexels_list, selectImage, image_cropper_watcher, selected_image
        }
    },
})
</script>
