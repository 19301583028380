<template>
<div>
    <Sidebar  v-model:visible="display" position="full"  :dismissable="false" :modal="true" :showCloseIcon="false" >
        <div v-if="is_ready" class="text-center align-items-center mt-5 pt-5">
            <button type="button" :disabled="cropping_status" @click="display = false" class="cursor-pointer btn btn-md btn-secondary om-add-contact-toggle mr-2">Cancel</button>
            <button @click="createBlob" type="button" :disabled="cropping_status"    class="btn btn-md btn-info save mr-5">
                <span  v-if="!cropping_status">  Crop</span>
                <span v-else><i class="fas fa-spinner fa-spinner"></i> Cropping...</span>
            </button>
        </div>

        <div class=" pt-5 mt-5">

            <div v-if="!is_ready" class="text-center align-items-center mt-5 pt-5">
                <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        <cropper
            ref="cropper"
            :src="src"
            :stencil-props="{
                aspectRatio: ratioW/ratioH
            }"
            @ready="ready"
            :canvas="{
                minHeight: ratioH,
                minWidth: ratioW,
                maxHeight: ratioH,
                maxWidth: ratioW,
            }"
        />
        </div>
    </Sidebar>
</div>
</template>
<script>
import { defineComponent, computed, watch, inject, ref} from 'vue'
import Sidebar from 'primevue/sidebar';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { useStore  } from 'vuex'
import ProgressSpinner from 'primevue/progressspinner';
export default defineComponent({
    components: { Sidebar, Cropper},
      props: {
        image_cropper_watcher: {
            type: Number,
            default: 1
        },
        selected_image: {
            default: []
        },
        image_type: {
            type: String,
            default: 'link'
        },
        source: {
            type: String,
            default: 'survey_thumb'
        },
        ratioH:  {
            type: Number,
            default: 1
        },
        ratioW:  {
            type: Number,
            default: 1
        },
        modules: {
            type: String,
            default: 'SurveyModule/SET_SURVEY_THUMB'
        }
    },
    setup(props,  { root }) {
        const { constants, http, store} = inject('App')
        const { closeDialog } = inject('CropperParent')

        const display = ref(false)
        const cropping_status = ref(false)
        const cropper = ref()
        const src = ref('')
        const is_ready = ref(false)
        function change({ coordinates, canvas }) {
			//console.log(coordinates, canvas)
		}
        function createBlob() {
			const { canvas } = cropper.value.getResult();
			if (canvas) {
				canvas.toBlob(blob => {
                    uploadImage(blob)
				}, 'image/jpeg');
			}
		}
        async function uploadImage(blob) {
            try {
                cropping_status.value = true
                const f = new FormData();       
                f.append('file', blob)
                f.append('source', props.source)

                const response = await http.post(constants.UPLOAD_IMAGE, f)

                store.commit(props.modules, response.data.data)
                  
                cropping_status.value = false
                display.value = false
                closeDialog()
            } catch (err) {
                cropping_status.value = false
            }
        }
        function ready() {
            is_ready.value = true
        }

        watch(()=>props.image_cropper_watcher, (n) => {
            display.value = true
            is_ready.value = false
            if (props.image_type == 'link')
                src.value = props.selected_image.src.large
            else 
                src.value =props.selected_image
        })
        return {
            display, cropping_status, createBlob, cropper, src, ready, is_ready
        }
    },
})
</script>
<style  scoped>
.cropper {
	height: 400px;
}
.p-dialog .p-dialog-content {
    padding: 0px !important;
    background: #000 !important;
}
</style>