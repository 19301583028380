<template>
   <Sidebar  v-model:visible="visible" position="right" class="p-sidebar-lg"  :dismissable="true" :modal="true" :showCloseIcon="false" >
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Survey Results</p>
                    <small >{{ selected_survey.name }} / {{selected_respondent.fname}} {{selected_respondent.lname}}  / {{ moment(selected_respondent.created_at).format('YYYY-MM-DD hh:mm a') }} </small>
                </div>
                <div  style="width: 150px !important">
                    <a href="Javascript:void(0)" class="btn btn-light-primary mr-10" @click="generateReport">Print</a>
                    <span @click="visible = false"  class="cursor-pointer fs-20 text-red"><i class="pi pi-times text-danger"></i></span>
                </div>
            </div>
        </div>
    <div class="drawer-content">
        <div class=" pt-5 pb-10 mb-10 p-10" >
            <vue3-html2pdf
                            :show-layout="true"
                            :float-layout="false"
                            :enable-download="false"
                            :preview-modal="true"
                            filename="class-report"
                            :manual-pagination="true"
                            pdf-orientation="portrait"
                            pdf-content-width="100%"
                            ref="html2Pdf"
                            :html-to-pdf-options="pdfOptions">
                    <template v-slot:pdf-content>
                        <section>
                                <div v-for="(item, i) in survey_respodent_results_list" :key="i" class="mb-5  mt-5">
                                    <div class="box-shadow mt-3 pb-5 ">
                                            <p class="fw-600">{{i + 1}}.   {{item.question}}</p>
                                            <div v-for="(answers, x) in item.answers" :key="x" class="box-shadow bg-gray pb-0 mt-2 no-shadow shadow-none">
                                            <p v-if="item.question_type == 1 || item.question_type == 2 || item.question_type == 4 || item.question_type == 6"> {{answers.optionname.options}}</p>
                                            <p v-else> {{answers.answer_text}}</p>
                                            </div>
                                    </div>
                                </div>
                        </section>
                    </template>
            </vue3-html2pdf>
        </div>
    </div>
  </Sidebar>
</template>
<script>
import Sidebar from 'primevue/sidebar';
import { defineComponent, ref, watch, inject } from 'vue'
import  SurveyGetRespondentSurveyResults  from '@/composables/survey/SurveyGetRespondentSurveyResults'
import moment from 'moment'
import Vue3Html2pdf from 'vue3-html2pdf'

export default defineComponent({
    components: { Sidebar, Vue3Html2pdf },
    props: {
        survey_respondent_results_watcher: {
            default: 1,
            type: Number
        },
        selected_survey: {
            default: []
        },
        selected_respondent: {
            default: {}
        }
    },
    setup(props) {
        const { http, constants } = inject('App')

        const { respondent_survey_loading, survey_respodent_results_list, getSurveyRespondentResults } = SurveyGetRespondentSurveyResults(http, constants)

        const visible = ref(false)

         const pdfOptions = ref(
          {
            margin: 15,
            image: {
                type: 'jpeg',
                quality: .95,
            },
          }
        )
        const html2Pdf = ref()
    
        function generateReport () {
            html2Pdf.value.generatePdf()
        }

        watch(()=>props.survey_respondent_results_watcher, (n) => {
            visible.value = true
            getSurveyRespondentResults(props.selected_respondent.id, props.selected_survey.id)
            
        })

        return {
            visible, respondent_survey_loading, survey_respodent_results_list, moment,
            pdfOptions, html2Pdf, generateReport
        }
    },
})
</script>
