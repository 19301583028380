import { ref } from 'vue'
export default function SurveyDelete(http, constant, toast) {
    const delete_status = ref(false)
    async function deleteSurvey(id) {
        try {
            delete_status.value = true
            const f = new URLSearchParams()
            f.append('id', id)
            const response = await http.post(constant.SURVEY_DELETE, f)
            toast.add({severity:'success', summary: 'Success', detail:'Survey was successfully deleted.', life: 3000});

            delete_status.value = false
        } catch (err) {
            delete_status.value = false
        }
    }
    return {
        delete_status,
        deleteSurvey
    }
}