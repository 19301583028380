import { ref } from 'vue'
export default function ImageLibraryGetImages(http, constant) {
    const image_library_loading = ref(false)
    let image_library_list = ref([])

    async function getImageLibrary(type, path) {
        try {
            image_library_loading.value = true
            image_library_list.value = []

            const f = new URLSearchParams();
            f.append('thumbnail_type', type)
            f.append('path', path)

            const response = await http.post(constant.IMAGE_LIBRARY_GET_LIST, f)
            image_library_loading.value = false
            image_library_list.value = response.data.photos

        } catch (err) {
            image_library_loading.value = false
        }
    }
    return {
        image_library_list,
        image_library_loading,
        getImageLibrary
    }
}