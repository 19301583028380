import { ref } from 'vue'
export default function SurveyGetList(http, constant, store) {
    let loading = ref(false)
    let survey_list = ref([])
    let default_survey = ref([])

    async function getSurveyList() {
        loading.value = true;
        try {
            const response = await http.post(constant.SURVEY_LIST, [])
            loading.value = false
            survey_list.value = response.data.data.survey
            default_survey.value = response.data.data.default_survey
        } catch (err) {
            loading.value = false;
        }
    }
    return {
        loading, default_survey,  survey_list, getSurveyList
    }
}