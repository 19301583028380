import { ref } from 'vue'
export default function SurveyGetRespondentSurveyResults(http, constant) {
    let respondent_survey_loading = ref(false)
    let survey_respodent_results_list = ref([])

    async function getSurveyRespondentResults(respondent_id, survey_id) {
        respondent_survey_loading.value = true;
        survey_respodent_results_list.value = []
        try {

            const f = new URLSearchParams()
            f.append('respondent_id', respondent_id)
            f.append('survey_id', survey_id)

            const response = await http.post(constant.SURVEY_GET_RESPONDENT_SURVEY_RESULTS, f)
            respondent_survey_loading.value = false
            survey_respodent_results_list.value = response.data.data.results
        } catch (err) {
            respondent_survey_loading.value = false;
        }
    }
    return {
        respondent_survey_loading,   survey_respodent_results_list, getSurveyRespondentResults
    }
}